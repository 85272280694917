<template>
	<div id="register">
		<div class="rl-notice">
            <span class="red">註：</span>
            <span>此賬號為普通用戶（顧客）賬號，與關聯APP“大醫”互通  如欲<a href="https://os.ectcm.com/Login" class="label-link">登入</a>或<router-link to="/os/doctor_signup" class="label-link">註冊</router-link>中醫在綫管理系統</span>
        </div>
		<div class="rl-container">
			<div class="rl-tabs">
                <router-link to="/login" class="rl-tab login">登錄</router-link>
				<span class="divider"></span>
				<router-link to="/register" class="rl-tab register current">註冊</router-link>
			</div>
            <div class="register-form">
                <div class="form-item gender">
                    <div class="gender-item" @click="clientSex == 1 ? clientSex ='' : clientSex = '1' ">
                        <span :class="{'icon-gender': true, 'checked': clientSex == 1}"></span>
                        <span :class="{'label-gender': true, 'checked': clientSex == 1}">男</span>
                    </div>
                    <div class="gender-item" @click="clientSex == 2 ? clientSex ='' : clientSex = '2' ">
                        <span :class="{'icon-gender': true, 'woman': true, 'checked': clientSex == 2}"></span>
                        <span :class="{'label-gender': true, 'woman': true, 'checked': clientSex == 2}">女</span>
                    </div>
                </div>
                <div class="form-item">
                    <input type="text" placeholder="請輸入中文姓名" v-model.trim="clientname">
                </div>
                <div class="form-item">
                    <input type="tel" @input="onPhoneNumberChange" placeholder="輸入註冊手機號碼" v-model="phoneNumber">
                </div>
                <div class="form-item">
                    <input type="password" placeholder="輸入賬號密碼" v-model="password">
                </div>
                <div class="form-item">
                    <input type="number" placeholder="輸入短信驗證碼" v-model="phoneCode">
                    <div @click="getVCode" :class="{'get-phone-code': true, 'disable': !isPhoneNumber}">{{timer >= 0 ? `${timer}秒後可重發` : '獲取驗證碼'}}</div>
                </div>
                <div @click="onCheckboxChange" :class="{'agree-protocol': true, 'checked': signedPrivacy}">
                    <div class="my-checkbox"></div>
                    <div>我已閱讀並接受本站<router-link to='/home/privacy'>隱私條款</router-link>和<router-link to="/home/disclaimer">免責聲明</router-link></div>
                </div>
                <div class="btn-register" @click="register"><div class="lds-ring" v-if="loading"><div></div><div></div><div></div><div></div></div>{{loading ? '正在註冊...' : '免費註冊'}}</div>
            </div>
		</div>
        <div class="btn-home" @click="toHome">
            <span class="icon-back"></span>
            <span>返回首頁</span>
        </div>
	</div>
</template>

<script>
	export default {
        data(){
            return {
                timer: -1,
                loading: false,
                clientname: '',
                codeKey: '',
                password: '',
                phoneCode: '',
                phoneNumber: '',
                clientSex: '',
                signedPrivacy: false,
                isPhoneNumber: false,
            }
        },
        methods: {
            toHome: function() {
                window.location.href = "/"
            },
            getVCode: function() {
                if(this.timer > 0) return
                if(/^1[3-9]{1}[0-9]{9}$/.test(this.phoneNumber.trim()) || /^([5|6|8|9])\d{7}$/.test(this.phoneNumber.trim())) {
                    this.$store.dispatch('getVCode',{codeType: 1, phoneNumber: this.phoneNumber})
                    .then((rs) => {
                        this.codeKey = rs.codeKey
                        this._toast.success(this, '驗證碼已經發送')
                        this.timer = 90
                        var interval = setInterval(() => {
                            if(this.timer == 0) {
                               interval && clearInterval(interval)
                            }
                            this.timer --
                        }, 1000);
                    }).catch((msg) => {
                        this.timer = -1
                        this._toast.warning(this, msg || '操作失敗，請稍候重試')
                    })
                } else {
                    this._toast.warning(this, '手機號輸入不正確')
                }
            },
            onPhoneNumberChange: function(){
                if(/^1[3-9]{1}[0-9]{9}$/.test(this.phoneNumber.trim()) || /^([5|6|8|9])\d{7}$/.test(this.phoneNumber.trim())) {
                    this.isPhoneNumber = true
                } else {
                    this.isPhoneNumber = false
                }
            },
            onCheckboxChange: function(){
                this.signedPrivacy = !this.signedPrivacy
            },
            register: function(){
                if(!this.clientSex) {
                    this._toast.warning(this, '請選擇性別')
                    return
                }
                if(!this.clientname) {
                    this._toast.warning(this, '請輸入中文姓名')
                    return
                }
                if(!this.phoneNumber.trim()) {
                    this._toast.warning(this, '請輸入手機號碼')
                    return
                }
                if(! /^1[3-9]{1}[0-9]{9}$/.test(this.phoneNumber.trim()) && ! /^([5|6|8|9])\d{7}$/.test(this.phoneNumber.trim())) {
                    this._toast.warning(this, '手機號格式不正確')
                    return
                }
                if(! this.password.trim()) {
                    this._toast.warning(this, '請輸入賬號密碼')
                    return
                }
                if(this.password.trim().length < 6) {
                    this._toast.warning(this, '賬號密碼長度至少需要6位')
                    return
                }
                if(!this.phoneCode.trim()) {
                    this._toast.warning(this, '請輸入短信驗證碼')
                    return
                }
                if(!this.signedPrivacy) {
                    this._toast.warning(this, '請勾選”我已閱讀並接受本站隱私條款和免責聲明“')
                    return
                }

                this.loading = true
                var params = {
                    clientName: this.clientname,
                    codeKey: this.codeKey,
                    confirmPassword: this.password,
                    password: this.password,
                    phoneCode: this.phoneCode,
                    phoneNumber: this.phoneNumber.trim(),
                    signedPrivacy: this.signedPrivacy,
                    sourceType: 'F',
                    clientSex: this.clientSex
                }

                this.$store.dispatch('register', params).then(() => {
					this._toast.success(this, '註冊成功')
                    this.$store.dispatch('login', {
                        loginName: this.phoneNumber.trim(),
                        password: this.password
                    }).then(() => {
                        this.$router.push({path: '/'}); //登录成功之后重定向到首页
                        this.loading = false
                    }).catch((error) => {
                        this.loading = false
                        this.$router.push({path: '/login'});
                        this._toast.warning(this, error || '操作失敗')
                    })
                }).catch((rs) => {
                    rs && rs.msg && this._toast.warning(this, rs.msg)
                    this.loading = false
                })
            }
        }
	};
</script>

<style lang="scss" scoped>
    #register {
        background-image: url(../../assets/imgs/pc/bg_login@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 100%;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    #register .btn-home {
        position: absolute;
        right: 50px;
        top: 30px;
        border: 1px solid #36C4D0;
        border-radius: 10px;
        height: 50px;
        padding: 0px 23px;
        font-size: 24px;
        font-weight: bold;
        color: #36C4D0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        user-select: none;
        cursor: pointer;
    }

    #register .btn-home:active {
        opacity: 0.7;
    }

    #register .btn-home > .icon-back {
        width: 34px;
        height: 30px;
        margin-right: 13px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../../assets/imgs/pc/ic_back@2x.png);
    }

    #register .rl-notice {
        position: absolute;
        left: 0px;
        bottom: 24px;
        margin-left: 26px;
        font-size: 14px;
        font-weight: 400;
        color: #646464;
        display: flex;
        flex-direction: row;
    }

    #register .rl-notice > .red {
        color: #EE1C24;
    }

    #register .rl-notice .label-link {
        color: #FF8000;
        text-decoration: underline;
    }

    #register > .rl-container {
        width: 50%;
        height: 100%;
        margin-left: 50%;
        display: flex;
        flex-direction: column;
    }

    #register .rl-container .rl-tabs {
        width: 213px;
        height: 49px;
        margin-top: 16%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }

    #register .rl-container .rl-tabs .rl-tab {
        font-size: 34px;
        font-weight: bold;
        line-height: 34px;
        color: #231F20;
        cursor: pointer;
        position: relative;
        height: 49px;
        box-sizing: border-box;
        padding-bottom: 15px;
        white-space: nowrap;
    }

    #register .rl-container .rl-tabs .rl-tab.current {
        color: #36C4D0;
    }

    #register .rl-container .rl-tabs .rl-tab::after {
        position: absolute;
        content: '';
        bottom: 0;
        width: 14px;
        height: 6px;
        background-color: transparent;
        left: 50%;
        transform: translateX(-50%);
    }

    #register .rl-container .rl-tabs .rl-tab.current::after {
        background-color: #36C4D0;
    }

    #register .rl-container .rl-tabs .divider {
        width: 1px;
        height: 16px;
        margin-top: 9px;
        margin-left: 38px;
        margin-right: 38px;
        background-color: #EDEDED;
        display: block;
    }

    #register .register-form {
        margin-top: 76px;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    #register .register-form > .form-item {
        height: 48px;
        width: 60%;
        max-width: 540px;
        padding-left: 50px;
        margin-top: 10px;
        line-height: 48px;
        border-bottom: 1px solid #E8E8E8;
        box-sizing: border-box;
        position: relative;
    }

    #register .register-form > .form-item.gender {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    #register .register-form > .form-item.gender > .gender-item {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 35px;
        cursor: pointer;
        user-select: none;
    }

    #register .register-form > .form-item.gender > .gender-item > .label-gender {
        font-size: 16px;
        color: #969696;
        font-weight: 400;
    }

    #register .register-form > .form-item.gender > .gender-item > .label-gender.checked {
        font-weight: bold;
        color: #1998f2;
    }

    #register .register-form > .form-item.gender > .gender-item > .label-gender.woman.checked {
        color: #f45544;
    }

    #register .register-form > .form-item.gender > .gender-item > .icon-gender {
        margin-right: 4px;
        width: 32px;
        height: 32px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../../assets/imgs/pc/ic_man_normal.png);
    }

    #register .register-form > .form-item.gender > .gender-item > .icon-gender.woman {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../../assets/imgs/pc/ic_woman_normal.png);
    }

    #register .register-form > .form-item.gender > .gender-item > .icon-gender.checked {
        background-image: url(../../assets/imgs/pc/ic_man_checked.png);
    }

    #register .register-form > .form-item.gender > .gender-item > .icon-gender.woman.checked {
        background-image: url(../../assets/imgs/pc/ic_woman_checked.png);
    }

    #register .register-form > .form-title {
        font-size: 20px;
        font-weight: bold;
        line-height: 20px;
        color: #231F20;
        margin-left: 14px;
        margin-bottom: 40px;
        padding-top: 10px;
    }

    #register .register-form > .form-item:hover {
        border-bottom-color: #7BD5DF;
    }

    #register .register-form > .form-item:nth-child(1) {
        margin-top: 0px;
    }

    #register .register-form > .form-item::before {
        position: absolute;
        content: '';
        left: 16px;
        bottom: 15px;
        width: 18px;
        height: 17px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    #register .register-form > .form-item:nth-child(2)::before {
        background-image: url(../../assets/imgs/pc/icon_zhanghao@2x.png);
        width: 18px;
        height: 17px;
    }

    #register .register-form > .form-item:nth-child(3)::before {
        background-image: url(../../assets/imgs/pc/icon_dianhua@2x.png);
        width: 18px;
        height: 17px;
    }

    #register .register-form > .form-item:nth-child(4)::before {
        background-image: url(../../assets/imgs/pc/icon_mima@2x.png);
        width: 18px;
        height: 17px;
    }

    #register .register-form > .form-item:nth-child(5)::before {
        background-image: url(../../assets/imgs/pc/icon_yanzhenma@2x.png);
        width: 18px;
        height: 17px;
    }

    #register .register-form > .form-item:nth-child(5) {
        position: relative;
    }

    #register .register-form > .form-item:nth-child(5) > .get-phone-code {
        position: absolute;
        right: 0;
        height: 28px;
        line-height: 26px;
        box-sizing: border-box;
        border: 1px solid #FF8000;
        top: 10px;
        padding: 0px 9px;
        font-size: 16px;
        color: #FF8000;
        font-weight: 400;
        border-radius: 14px;
        cursor: pointer;
    }

    #register .register-form > .form-item:nth-child(5) > .get-phone-code.disable {
        color: #969696;
        border-color: #969696;
    }

    #register .register-form > .form-item > input {
        display: block;
        width: 100%;
        height: 46px;
        margin-bottom: 2px;
        color: #231F20;
        font-weight: bold;
        font-size: 16px;
    }

    #register .register-form > .btn-register {
        user-select: none;
        margin-top: 8%;
        width: 270px;
        height: 84px;
        font-size: 18px;
        color: #fff;
        font-weight: bold;
        line-height: 4;
        text-align: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../../assets/imgs/pc/img_anniu@2x.png);
        cursor: pointer;
    }

    #register .rl-content .tab-content > .forget-password {
        position: absolute;
        left: 241px;
        bottom: 145px;
        text-decoration: underline;
        font-weight: 400;
        color: #636363;
        line-height: 14px;
        font-size: 14px;
    }

    #register .register-form .agree-protocol {
        display: flex;
        flex-direction: row;
        margin-top: 4%;
        justify-content: flex-start;
        align-items: center;
        margin-left: 0px;
        font-size: 12px;
        line-height: 13px;
        font-weight: 400;
        color: #969696;
        cursor: pointer;
    }

    #register .register-form .agree-protocol a {
        color: #231F20;
        font-size: 12px;
        line-height: 13px;
        font-weight: 400;
        text-decoration: underline;
    }

    #register .register-form .agree-protocol.checked a,
    #register .register-form .agree-protocol.checked {
        color: #36C4D0;
    }

    #register .register-form .agree-protocol > .my-checkbox {
        width: 12px;
        height: 12px;
        border: 1px solid #969696;
        box-sizing: border-box;
        cursor: pointer;
        margin-right: 8px;
    }

    #register .register-form .agree-protocol.checked > .my-checkbox {
        border-color: #36C4D0;
        position: relative;
    }

    #register .register-form .agree-protocol.checked > .my-checkbox::after {
        content: '';
        position: absolute;
        left: -1px;
        top: -2px;
        width: 16px;
        height: 13px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../../assets/imgs/pc/img_goux@2x.png);
    }

    /** loading start */
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 32px;
        height: 32px;
    }
    
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        margin: 4px;
        border: 4px solid #fff;
        border-radius: 50%;
        margin-top: 14px;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }
    
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    /** loading end */ 
</style>